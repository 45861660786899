import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import LEDTable from 'components/Products/IN-Motion/500/Dual_Sensor/LEDTable';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-Motion Motion Detection Sensors",
  "path": "/Products/IN-Motion/500/Dualer_Sensor/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Der IN-Motion 500 verfügt über eine Mikrowelle und einen passiven Infrarot-Bewegungsmelder. Es ist perfekt für die Outdoor-Überwachung geeignet.",
  "image": "../../P_SearchThumb_MotionDetector.png",
  "social": "/images/Search/P_SearchThumb_MotionDetector.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Motion_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-Motion 500 Motion Detection Sensors' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-Motion 500 comes with a microwave and a passive infrared motion sensor. It is the perfectly suited for outdoor detection.' image='/images/Search/P_SearchThumb_MotionDetector.png' twitter='/images/Search/P_SearchThumb_MotionDetector.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/IN-Motion/500/Dual_Sensor/' locationFR='/fr/Products/IN-Motion/500/Dual_Sensor/' crumbLabel="Sensor" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-motion-500",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-motion-500",
        "aria-label": "in motion 500 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-Motion 500`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Diskutieren Sie diesen Artikel im `}<a parentName="p" {...{
          "href": "https://forum.instar.de/c/andere-produkte/7"
        }}>{`INSTAR Forum`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "wie-funktioniert-ein-dualer-bewegungsmelder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#wie-funktioniert-ein-dualer-bewegungsmelder",
        "aria-label": "wie funktioniert ein dualer bewegungsmelder permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wie funktioniert ein dualer Bewegungsmelder?`}</h2>
    <h3 {...{
      "id": "wofür-steht-pir",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wof%C3%BCr-steht-pir",
        "aria-label": "wofür steht pir permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wofür steht PIR?`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "855px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/77800/IN-Motion500_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95.21739130434781%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACa0lEQVQ4y5WUC1PaQBSF8///TKfWKmIIGCBEEVsrBRLyIg/yJgomgEk8naxFQCO1O3Mnm93Jl3Pv3j0UDozn52cSu/Pd2KzvDuoQ7C3o7Y/KwNRnQcUz37yXwDdz6lB6m/H0lCJOVlg8JpgvYiziBMlyjSzL3gmhDoHSNIPr+BgORAiiAtNy4PsRHCeA64aYRXPEyXJfYVlxszTFzA9hTz34QQTXDSCNVXDtKwgjiaxPNAv9OxF85yd63T7CINrWcBe6mD/CNh1YlgvPj0hMpz4Mw4Eq69A1E8JQBtfqga5yqJ13QJ93wLVusFqttwrTNEUYzOBMXTi2B8+bkdR03YaiGJAVA+OxitveHeoF5IxDvcbjotFFk70GW+cRx8lfhQACP4SpW/C8EJZpw9BMokZTTaiaCXEkgW92QZ82t7D6FRpMB/VaG7KkbU85SZaYmg4cxydA03AgKzqksQZhIKN3eQum0kT1exO1AkbzuGAuQVdYtNkr8s1rYxfUwJ/BMhwSBUzTLJKmOFLQu/yFylFjD8ZU2zg7ZvDj+g7L5WqvQ6iil2zLhaXbMCYW9MkURlE3SUf/VgB3cY2zY/YFVu2g8o3B+UkDmmKUNjeV5zk5CLOAFfWSi1QnEAUNo4GCdqML+qSF2mkL379UwbFdPNzPP7wMpIb30QN01XgFjgWVwIrgmzeofGVQOaph2B8jy3ICyPNyg6BeNnNEYQRDNSCLCsSRjNFAwvC3hBbDg6U5+G747pqVus3uwnq1Jgc00cxXlbKokYb9133fU/h2s1AcF0bwsNgqyT/2wFKFZb73WVWlBvu/zvwR8A9z1qChzIY+AQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/e4706/IN-Motion500_01.avif 230w", "/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/d1af7/IN-Motion500_01.avif 460w", "/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/09f2b/IN-Motion500_01.avif 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/a0b58/IN-Motion500_01.webp 230w", "/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/bc10c/IN-Motion500_01.webp 460w", "/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/31c30/IN-Motion500_01.webp 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/81c8e/IN-Motion500_01.png 230w", "/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/08a84/IN-Motion500_01.png 460w", "/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/77800/IN-Motion500_01.png 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/06b272be03f0f4e854f8f6c76f2cc1f1/77800/IN-Motion500_01.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Der IN-Motion 500 Dual-PIR- und Mikrowellen-Bewegungsmelder für IP-Kameras.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "855px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/895fa17b43a0dda169a989188de1f05f/77800/IN-Motion500_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95.21739130434781%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACjklEQVQ4y42SW2/aQBCF8+f7UrUPrdS+NC9VW6UJUVpCaCHkBuRCwBhjg9d3e9fYxhAw2CbGVypwUokkSnK02tVo9M0ZzezG4iXFcXLrBZPJbDx2rbHrOPMgjNLUxvNkGEYDbaBzisbB5WGgSksqhyYT9yGcrHQfLK9bz0dNSsdIvctrbabXZtQGJZzVERo+55wWmXk+ImgD9s0bu9+3dGOsQpOrNCVRX4PjOB6NRq7rLqIo8YPYDxdR5ExspU4YAjL0GwMNekpf4VVwcs1xvTs4bdV13SaOQ0VJptNwNArH42RiTXuaVK5pPNLVoQZNKBsiA6nSJQPQy207M09stHusoqoDDZqyZHBdmfh7Dmj43MDS1731xOsW6vKKqMos5GkF4CyWO3sCfuzszn2+UpMy+2L+iM8W2d9F+uc+tv0H/G87dfN9nyRJgWVDzZhD5CM1VHsWw8mF4+pOdvPtx823Hz6/ef/13Sdsr0TTyppzFEXD4dC27bVVzQP65Jz6tkNksq2tvcb33asvP+rbeQDgq/ZMHFZAi5G0kaD0Aa83GxyeP+VZ9IqBeT51csnitCjrLAPJtojXulSxsgYn97oLoigOw0UcO86MOqoyOBAVg2FgmxCbV12yUH7CObULjP6cF3wFBhBZDEdl9tk2J0KTZlCblLEaoAplgVeXMM/zGIaJoshxHMtxM8eZd0EShmm1WRixtRZfOhOq18zxReewSuaOifypIKy+p+d59krWSlEc+5LsIxSYZmialgTpg1JnN9fJH1HZIvGr0MocVLZyDFCennYSBLFtx46TuK47spjDMl66aBF8s97BGqBZI+u7eVU1l3DySA9HMJlaZHdAgX6LGlJg3AEzqKbZf3bfFRnPoz4WAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/895fa17b43a0dda169a989188de1f05f/e4706/IN-Motion500_02.avif 230w", "/en/static/895fa17b43a0dda169a989188de1f05f/d1af7/IN-Motion500_02.avif 460w", "/en/static/895fa17b43a0dda169a989188de1f05f/09f2b/IN-Motion500_02.avif 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/895fa17b43a0dda169a989188de1f05f/a0b58/IN-Motion500_02.webp 230w", "/en/static/895fa17b43a0dda169a989188de1f05f/bc10c/IN-Motion500_02.webp 460w", "/en/static/895fa17b43a0dda169a989188de1f05f/31c30/IN-Motion500_02.webp 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/895fa17b43a0dda169a989188de1f05f/81c8e/IN-Motion500_02.png 230w", "/en/static/895fa17b43a0dda169a989188de1f05f/08a84/IN-Motion500_02.png 460w", "/en/static/895fa17b43a0dda169a989188de1f05f/77800/IN-Motion500_02.png 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/895fa17b43a0dda169a989188de1f05f/77800/IN-Motion500_02.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Der IN-Motion 500 Dual-PIR- und Mikrowellen-Bewegungsmelder für IP-Kameras.`}</p>
    <p>{`PIR steht für Passives Infrarot oder Pyroelektrisches Infrarot. PIR-Sensoren können Sensoren sein, die als Detektoren von sich bewegenden warmen Körpern definiert werden. Ein stationäres Ziel kann nicht erkannt werden. Um ein sich bewegendes Ziel zu erkennen, muss das Ziel eine Oberflächentemperatur haben, die sich wesentlich von der Umgebungstemperatur unterscheidet. Obwohl die üblichen Ziele eines PIR-Detektors warm sind (Säugetiere, Vögel), erfasst der PIR-Sensor auch ein sich bewegendes Ziel mit einer Oberflächentemperatur, die deutlich unter der Umgebungstemperatur liegt. Solange das Ziel groß genug ist und seine Oberflächentemperatur erkennbar von der Umgebung (höher oder niedriger) abweicht - und sich das Ziel bewegt, kann es von einem PIR-Sensor erfasst werden.`}</p>
    <h3 {...{
      "id": "wie-funktioniert-der-mikrowellenteil",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wie-funktioniert-der-mikrowellenteil",
        "aria-label": "wie funktioniert der mikrowellenteil permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wie funktioniert der Mikrowellenteil?`}</h3>
    <p>{`Der Mikrowellensensor erkennt Bewegungen durch das Prinzip des Doppler-Radars. Er ähnelt einem Radar-Speed-Gun. Es sendet Mikrowellenimpulse aus und misst die Reflexion von sich bewegenden Objekten. Sie decken einen größeren Bereich ab als Infrarotsensoren, sind jedoch anfälliger für elektrische Interferenzen. Mikrowellen-Bewegungsmelder unterscheiden sich von PIR-Bewegungsmeldern dadurch, dass sie aktive Geräte sind. Sie übertragen Energie und suchen nach einer Frequenzverschiebung (Doppler) aufgrund der Bewegung im abgedeckten Bereich. Der Mikrowellen-Bewegungsmelder sendet Hochfrequenzwellen aus und erfasst dann den Energiepegel, der zum Gerät zurück reflektiert wird. Wenn die Radiofrequenzwelle auf ein sich bewegendes Objekt trifft, ändert sich die Frequenz und der Bewegungsmelder wird aktiviert. Mikrowellen-Bewegungsmelder an sich würden häufige Fehlalarme auslösen, da Mikrowellenenergie in viele Baumaterialien eindringt.`}</p>
    <h3 {...{
      "id": "led-alarmanzeige",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#led-alarmanzeige",
        "aria-label": "led alarmanzeige permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LED Alarmanzeige`}</h3>
    <p>{`Die rote LED blinkt während der Initialisierung. Bitte warten Sie 15 Minuten, damit der Sensor betriebsbereit ist.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <LEDTable mdxType="LEDTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`Der Sensor sollte mindestens einmal im Jahr getestet werden!`}</strong>{` Aktivieren Sie die Status-LEDs (wenn deaktiviert), trennen Sie die Stromversorgung und stellen Sie die Stromversorgung nach 10s wieder her. Warten Sie 15 Minuten, bis der Sensor initialisiert ist. Gehen Sie nun über den überwachten Bereich (innerhalb des optischen Sichtfelds des Sensors). Wenn die Empfindlichkeit auf STANDARD eingestellt ist, sollte die rote Alarm-LED nach etwa drei bis fünf Schritten aufleuchten. Warten Sie bei jedem Einschalten der LED, bis sie wieder erlischt. Warten Sie dann 15 Sekunden, bevor Sie den Gehtest fortsetzen. Wenn sich im überwachten Bereich keine Bewegung befindet, sollte die LED aus bleiben.`}</p>
    <h2 {...{
      "id": "pir--know-how--rücksichtnahme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pir--know-how--r%C3%BCcksichtnahme",
        "aria-label": "pir  know how  rücksichtnahme permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PIR :: Know-how & Rücksichtnahme`}</h2>
    <h3 {...{
      "id": "was-ist-ein-pir--und-mikrowellen-bewegungsmelder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#was-ist-ein-pir--und-mikrowellen-bewegungsmelder",
        "aria-label": "was ist ein pir  und mikrowellen bewegungsmelder permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Was ist ein PIR- und Mikrowellen-Bewegungsmelder?`}</h3>
    <h4 {...{
      "id": "pir-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#pir-sensor",
        "aria-label": "pir sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PIR Sensor`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "220px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9eb114ab44397a4e22483c5d3b409d88/27dc7/IN-Motion500_18.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.909090909090914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB6rzZOVEv/8QAGxABAQACAwEAAAAAAAAAAAAAAgEEEgADMTL/2gAIAQEAAQUCDihW0x92IDOdfmP8/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHhAAAgIABwAAAAAAAAAAAAAAAAECESFRUnKBkbH/2gAIAQEABj8CwORuWplqKvM6Jb36f//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExURBBsf/aAAgBAQABPyEMrX08y5eKRj1kYpoERCVdg2AFXfx46//aAAwDAQACAAMAAAAQoC//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8QFT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAGxABAQADAQEBAAAAAAAAAAAAAREAIWExUdH/2gAIAQEAAT8Qceouoo0k4+5aAHolLq9cJ2jeA+b+VM1SmiKu64BAB1hzgCRP3Z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9eb114ab44397a4e22483c5d3b409d88/6d654/IN-Motion500_18.avif 220w"],
              "sizes": "(max-width: 220px) 100vw, 220px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9eb114ab44397a4e22483c5d3b409d88/b5458/IN-Motion500_18.webp 220w"],
              "sizes": "(max-width: 220px) 100vw, 220px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9eb114ab44397a4e22483c5d3b409d88/27dc7/IN-Motion500_18.jpg 220w"],
              "sizes": "(max-width: 220px) 100vw, 220px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9eb114ab44397a4e22483c5d3b409d88/27dc7/IN-Motion500_18.jpg",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`PIR steht für Passives Infrarot oder Pyroelektrisches Infrarot. PIR-Sensoren können Sensoren sein, die als Detektoren von sich bewegenden warmen Körpern definiert werden. Ein stationäres Ziel kann nicht erkannt werden. Um ein sich bewegendes Ziel zu erkennen, muss das Ziel eine Oberflächentemperatur haben, die sich wesentlich von der Umgebungstemperatur unterscheidet. Obwohl die üblichen Ziele eines PIR-Detektors warm sind (Säugetiere, Vögel), erfasst der PIR-Sensor auch ein sich bewegendes Ziel mit einer Oberflächentemperatur, die deutlich unter der Umgebungstemperatur liegt. Solange das Ziel groß genug ist und seine Oberflächentemperatur erkennbar von der Umgebung (höher oder niedriger) abweicht - und sich das Ziel bewegt, kann es von einem PIR-Sensor erfasst werden.`}</p>
    <h4 {...{
      "id": "microwave-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#microwave-sensor",
        "aria-label": "microwave sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Microwave Sensor`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "220px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/751c03da0bc9c9941f911ac3349c22db/27dc7/IN-Motion500_19.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.90909090909091%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAbYpsSRkP//EABoQAAMBAAMAAAAAAAAAAAAAAAECAwAEEhP/2gAIAQEAAQUCQgqrdtTlVWskC0jiB6//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAgEFAAAAAAAAAAAAAAAAAQIRYQMxQXLB/9oACAEBAAY/Ak09x4dE0o3TFWeRmr28P//EABsQAAICAwEAAAAAAAAAAAAAAAERACExUXHw/9oACAEBAAE/IWphaY0EMtCvQSLxCQ40ZQF3Z7CnDwJ//9oADAMBAAIAAwAAABBDD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAAMAAwADAAAAAAAAAAAAAAERIQAxQWGBkf/aAAgBAQABPxBODKe/MBuDIpHQuYV42iAAFrw/eD2d0KLMaFwUXYtsmzhCCZz/AP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/751c03da0bc9c9941f911ac3349c22db/6d654/IN-Motion500_19.avif 220w"],
              "sizes": "(max-width: 220px) 100vw, 220px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/751c03da0bc9c9941f911ac3349c22db/b5458/IN-Motion500_19.webp 220w"],
              "sizes": "(max-width: 220px) 100vw, 220px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/751c03da0bc9c9941f911ac3349c22db/27dc7/IN-Motion500_19.jpg 220w"],
              "sizes": "(max-width: 220px) 100vw, 220px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/751c03da0bc9c9941f911ac3349c22db/27dc7/IN-Motion500_19.jpg",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Der Mikrowellensensor erkennt Bewegungen durch das Prinzip des Doppler-Radars. Er ähnelt einem Radar-Speed-Gun. Es sendet Mikrowellenimpulse aus und misst die Reflexion von sich bewegenden Objekten. Sie decken einen größeren Bereich ab als Infrarotsensoren, sind jedoch anfälliger für elektrische Interferenzen. Mikrowellen-Bewegungsmelder unterscheiden sich von PIR-Bewegungsmeldern dadurch, dass sie aktive Geräte sind. Sie übertragen Energie und suchen nach einer Frequenzverschiebung (Doppler) aufgrund der Bewegung im abgedeckten Bereich. Der Mikrowellen-Bewegungsmelder sendet Hochfrequenzwellen aus und erfasst dann den Energiepegel, der zum Gerät zurück reflektiert wird. Wenn die Radiofrequenzwelle auf ein sich bewegendes Objekt trifft, ändert sich die Frequenz und der Bewegungsmelder wird aktiviert. Mikrowellen-Bewegungsmelder an sich würden häufige Fehlalarme auslösen, da Mikrowellenenergie in viele Baumaterialien eindringt.`}</p>
    <h4 {...{
      "id": "dual-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#dual-sensor",
        "aria-label": "dual sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dual Sensor`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "220px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d7e28c781c07763e960a49f8f43713a3/27dc7/IN-Motion500_20.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.81818181818182%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHaLEFID//EABkQAAIDAQAAAAAAAAAAAAAAAAABAhExQf/aAAgBAQABBQJIRZyeH//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABwQAQACAQUAAAAAAAAAAAAAAAEAESEQMXGhwf/aAAgBAQABPyHC2dQYbiFnmbPOn//aAAwDAQACAAMAAAAQAM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMXHw/9oACAEBAAE/EGvCtQRsEQUDtZLoAVnSHx5GlT0DEL5P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7e28c781c07763e960a49f8f43713a3/6d654/IN-Motion500_20.avif 220w"],
              "sizes": "(max-width: 220px) 100vw, 220px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d7e28c781c07763e960a49f8f43713a3/b5458/IN-Motion500_20.webp 220w"],
              "sizes": "(max-width: 220px) 100vw, 220px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7e28c781c07763e960a49f8f43713a3/27dc7/IN-Motion500_20.jpg 220w"],
              "sizes": "(max-width: 220px) 100vw, 220px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d7e28c781c07763e960a49f8f43713a3/27dc7/IN-Motion500_20.jpg",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Bewegungsmelder, die PIR mit einem Mikrowellensensor kombinieren, sind die bessere, aber teurere Wahl. Da jeder in verschiedenen Bereichen des Spektrums arbeitet und einer passiv ist und einer aktiv ist, verursachen Bewegungssensoren mit zwei Technologien weniger wahrscheinlich als andere Typen Fehlalarme, da für den Alarm beide Sensoren ausgelöst werden müssen stolperte Daher wird der Fehlalarm im Vergleich zu einem einzelnen PIR-Sensor minimiert.`}</p>
    <p>{`Beachten Sie, dass Bewegungssensoren nicht fehlersicher sind. Fehlalarme werden in der Regel durch Lichtquellen mit hohem IR-Anteil und Heizsystemen (PIR) oder elektrischen Interferenzen durch Leuchtstofflampen und mobile Geräte (MW) verursacht.`}</p>
    <p>{`Platzieren Sie Bewegungssensoren an „Erstickungspunkten“ - Bereichen, in denen Personen durchgehen müssen, wie das Treppenhaus oder der Hauptflur. Auf diese Weise löst ein Eindringling den Sensor aus, unabhängig davon, wo er sich befindet. Nutzen Sie alle Vorteile der 3 Bewegungserkennungsmethoden.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "235px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1dd017ae95aaf0508f2d4711b2f06160/9fbb0/IN-Motion500_21.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe2oM6o//8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAAhAh/9oACAEBAAEFAi31k30z/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgEFAAAAAAAAAAAAAAAAAAExESBBYZH/2gAIAQEABj8CVIyaIXbP/8QAGhAAAgIDAAAAAAAAAAAAAAAAABEBMRAhcf/aAAgBAQABPyGXcMNreg5gTUlz/9oADAMBAAIAAwAAABBjz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB4QAAEDBAMAAAAAAAAAAAAAAAEAESEQMUFhcZGh/9oACAEBAAE/EBxi5hxcRDQoDbNnpMDHjJDJ1TzKzwv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1dd017ae95aaf0508f2d4711b2f06160/e4706/IN-Motion500_21.avif 230w", "/en/static/1dd017ae95aaf0508f2d4711b2f06160/083ea/IN-Motion500_21.avif 235w"],
              "sizes": "(max-width: 235px) 100vw, 235px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1dd017ae95aaf0508f2d4711b2f06160/a0b58/IN-Motion500_21.webp 230w", "/en/static/1dd017ae95aaf0508f2d4711b2f06160/e572b/IN-Motion500_21.webp 235w"],
              "sizes": "(max-width: 235px) 100vw, 235px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1dd017ae95aaf0508f2d4711b2f06160/e83b4/IN-Motion500_21.jpg 230w", "/en/static/1dd017ae95aaf0508f2d4711b2f06160/9fbb0/IN-Motion500_21.jpg 235w"],
              "sizes": "(max-width: 235px) 100vw, 235px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1dd017ae95aaf0508f2d4711b2f06160/9fbb0/IN-Motion500_21.jpg",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`PIR-Bewegungssensoren funktionieren am besten, wenn der Eindringling parallel zum Sensor läuft und nicht auf ihn zu. In einem Flur laufen Sie zum Beispiel parallel zu den Wänden und nicht direkt auf sie zu. Bei MW-Sensoren ist der Fall umgekehrt. Wenn Sie auf sie zugehen, wird dies viel früher erkannt. Wenn beide Sensoren über die UND-Logik gekoppelt sind, führt dies zu einem Problem. Richten Sie Ihren IN-Motion 500 so ein, dass beide Sensoren einen Alarm auslösen können (ODER Modus - `}<a href="/de/Products/IN-Motion/500/Setup/">{`DIP5 muss auf OFF stehen`}</a>{` beide Sensoren zu entkoppeln). Dies kann zu einer höheren Anzahl falscher Alarme führen. Diese können jedoch durch Kopplung des Sensors mit der internen, softwarebasierten Erkennung in Ihrer INSTAR HD-Kamera eingestellt werden. Bitte überprüfen Sie die Web-Benutzeroberfläche Ihrer Kamera unter Alarm / Action auf Details zu dieser Betriebsart.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      